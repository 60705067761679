import { useRuntimeConfig } from "#imports";
import * as Sentry from "@sentry/nuxt";

Sentry.init({
  dsn: useRuntimeConfig().public.sentry.dsn,

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for tracing.
  // We recommend adjusting this value in production
  tracesSampleRate:
    useRuntimeConfig().public.appEnv === "PRODUCTION" ? 0.2 : 1.0,
});
